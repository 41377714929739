import { FC } from "react";
import { EditButtonItem, DuplicateButtonItem } from "../buttons-item";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useHistory } from "react-router-dom";
import { WidgetTypeEnum } from "../../../enum";
import { useWinnerItemStyles } from "./widgets-actins-button-styles.hook";

interface IProps {
  row: Record<string, any>;
  isCarousel: boolean;
  toggleModal: (row: Record<string, any>) => void;
  onDuplicate: (row: Record<string, any>) => void;
}

export const WidgetFeedActionsButton: FC<IProps> = ({ row, isCarousel, toggleModal, onDuplicate }) => {
  const history = useHistory();
  const styles = useWinnerItemStyles();

  return (
    <div className={styles.container}>
      {!isCarousel ? (
        <DuplicateButtonItem
          submit={() => onDuplicate(row)}
        />
      ): null}
      <EditButtonItem
        submit={() =>
          row.type === WidgetTypeEnum.CAROUSEL
            ? history.push("create-carousel/" + row.id)
            : row.type === WidgetTypeEnum.DROP
            ? history.push("widget/drop/" + row.id)
            : history.push("widget/" + row.id)
        }
      />
      <DeleteForeverIcon onClick={toggleModal} className={styles.icon} />
    </div>
  );
};
