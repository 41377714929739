import { axiosAuthorized } from "../axios";

const usersService = {
  async getUsers(
    row: number,
    page: number,
    order: string,
    sortByName: string,
    term: string
  ): Promise<Array<Record<string, any>>> {
    const data = await axiosAuthorized.get(
      `users/all?limit=${row}&pageNumber=${page}&fieldName=${sortByName}&order=${order}&term=${term}`
    );
    return data.data;
  },

  async setUserWhitelist(
    id: string,
    isWhitelisted: boolean
  ): Promise<Record<string, any>> {
    return axiosAuthorized.patch(
      process.env.REACT_APP_API_URL + "users/whitelist/" + id,
      {
        isWhitelisted,
      }
    );
  },

  // async setUserField(
  //   id: string,
  //   url: string,
  //   data: Record<string, any>
  // ): Promise<Record<string, any>> {
  //   return axiosAuthorized.patch(
  //     `${process.env.REACT_APP_API_URL}users/${url}/${id}`,
  //     data
  //   );
  // },

  async setUserExplicitSMS(
    id: string,
    explicitSms: boolean
  ): Promise<Record<string, any>> {
    return axiosAuthorized.patch(
      process.env.REACT_APP_API_URL + "users/explicit/" + id,
      {
        explicitSms,
      }
    );
  },

  async getUserInterests(
    row: number,
    page: number,
    order: string,
    sortByName: string,
    term: string,
    type: string
  ): Promise<Array<Record<string, any>>> {
    const paramType = type && type !== 'ALL' ? type: '';
    const stringTypeQuery = paramType ? `&type=${paramType}`: '';
    const data = await axiosAuthorized.get(
      `user-interests/all?limit=${row}&pageNumber=${page}&fieldName=${sortByName || 'name'}&order=${order || 'asc'}&term=${term || ''}${stringTypeQuery}`
    );
    return data.data;
  }
};

export default usersService;
