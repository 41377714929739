import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTING_CONFIG } from "./constants/routing-config/routing-config";
import { UsersPage, UserInterestsPage, CampaignsPage, SubmissionsPage, WidgetsPage } from "./pages";
import {
  CreateWidgetsPage,
  DetailsDropWidgetsPage,
  CreateWidgetsCarouselPage,
  CreateUsersInterestsPage,
  CreateCampaignsPage
} from "./pages/create-widgets";
import { AuthPage } from "./pages/auth";
import { ConfirmAuthPage } from "./pages/confirm-auth";
import { CarouselPage } from "./pages/carousel";
import { CarouselsPage } from "./pages/carousels";

type RoutesFunc = (
  isAuthenticated: boolean,
  isFirstRenderEnd: boolean
) => JSX.Element | null;

const useRoutes: RoutesFunc = (
  isAuthenticated: boolean,
  isFirstRenderEnd: boolean
) => {
  if (!isFirstRenderEnd) return null;

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route path={ROUTING_CONFIG.auth} component={AuthPage} />
        <Route path={ROUTING_CONFIG.confirmAuth} component={ConfirmAuthPage} />

        <Redirect from="*" to={ROUTING_CONFIG.auth} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={ROUTING_CONFIG.users} component={UsersPage} />
      <Route path={ROUTING_CONFIG.createUserInterest} component={CreateUsersInterestsPage} />
      <Route path={ROUTING_CONFIG.userInterest} component={CreateUsersInterestsPage} />
      <Route path={ROUTING_CONFIG.userInterests} component={UserInterestsPage} />
      <Route path={ROUTING_CONFIG.widgets} component={WidgetsPage} />
      <Route path={ROUTING_CONFIG.createWidget} component={CreateWidgetsPage} />
      <Route path={ROUTING_CONFIG.dropWidget} component={DetailsDropWidgetsPage} />
      <Route path={ROUTING_CONFIG.widget} component={CreateWidgetsPage} />
      <Route
        path={ROUTING_CONFIG.createwidgetCarousel}
        component={CreateWidgetsCarouselPage}
      />
      <Route path={ROUTING_CONFIG.submissions} component={SubmissionsPage} />
      <Route path={ROUTING_CONFIG.carousel} component={CarouselPage} />
      <Route path={ROUTING_CONFIG.carousels} component={CarouselsPage} />
      <Route path={ROUTING_CONFIG.createCampaign} component={CreateCampaignsPage} />
      <Route path={ROUTING_CONFIG.campaign} component={CreateCampaignsPage} />
      <Route path={ROUTING_CONFIG.campaigns} component={CampaignsPage} />
      <Redirect from="*" to={ROUTING_CONFIG.users} />
    </Switch>
  );
};

export { useRoutes };
