import ROUTING_CONFIG from "./routing-config";
import { useIntl } from "react-intl";
import { INavbarListItem } from "../../components/navbar/interface";

export const NAV_LIST = (): Array<INavbarListItem> => {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({ id: "users" }),
      link: ROUTING_CONFIG.users,
    },
    {
      name: intl.formatMessage({ id: "interests" }),
      link: ROUTING_CONFIG.userInterests,
    },
    {
      name: intl.formatMessage({ id: "widgets" }),
      link: ROUTING_CONFIG.widgets,
    },
    {
      name: intl.formatMessage({ id: "campaigns" }),
      link: ROUTING_CONFIG.campaigns,
    },
    {
      name: intl.formatMessage({ id: "submissions" }),
      link: ROUTING_CONFIG.submissions,
    },
    {
      name: intl.formatMessage({ id: "carousels" }),
      link: ROUTING_CONFIG.carousels,
    },
  ];
};
