export enum WidgetStatus {
  onTheBench = "On the Bench",
  itsAGameTime = "It's Game Time",
  hidden = "Hidden",
}

export enum WidgetStatusForCreateWidget {
  onTheBench = "Draft",
  itsAGameTime = "Live",
  hidden = "Hidden",
}

export enum WidgetTabLabel {
  feedOrder = "Feed Order",
  promotions = "Promotions",
}

export enum WidgetTabValue {
  feedOrder = "feedOrder",
  promotions = "promotions",
}

export enum DropTabLabel {
  details = "DETAILS",
  results = "RESULTS",
}

export enum DropTabValue {
  details = "details",
  results = "results",
}

export enum DropTransactionStatusEnum {
  IN_QUEUE = 'IN_QUEUE',
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS',
}

export enum DropStatusEnum {
  BUY_NOW = 'BUY_NOW',
  SOLD_OUT = 'SOLD_OUT',
  AUTOMATIC = 'AUTOMATIC',
}

export enum DropStatusLable {
  BUY_NOW = 'Buy Now',
  SOLD_OUT = 'Sold Out',
  AUTOMATIC = 'Automatic',
}

export enum InternalStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive"
}

export enum CampaignStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive"
}

export enum BackgroundType {
  COLOR = "COLOR",
  IMAGE = "IMAGE",
}

export enum ContainerType {
  LIGHT = "LIGHT",
  DARK = "DARK",
}

export enum WidgetFeedTitleFont {
  GT_AMERICA = 'GT America',
  ARGENT_CF = 'Argent CF',
}
