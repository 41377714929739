import React, { useState, useEffect } from "react";
import { toJS } from "mobx";
import moment from "moment-timezone";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { QuestionIcon } from "../../../../components/icons";
import { useIntl } from "react-intl";
import { ItemQuestionModal } from "../modals";
import image from "../../../../assets/images/testImage.png";
import { Accordion } from "../accordion";
import { dropResultsTableConfig } from "../../../widgets/widgets-table-config";
import { UiTableDrag } from "../../../../components/wilson-ui/table";
import WidgetsStore from "../../../../store/widgets/widgets.store";
import { NameItem } from "../../../../components/items";
import { DropTransactionStatusEnum } from "../../../../types";
const NAMES = ["Title", "Button Text", "Color"];
const defaultRowPerPage = 10;
const defaultPage = 1;
interface IProps {
  location: any;
}

export const MissedDrop: React.FC<IProps> = ({ location }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);
  const [sortState, setSortState] = useState<string>('');
  const [sortBy, setSortBy] = useState<any>(null);
  const [rowPerPage, setRowPerPage] = useState<number>(defaultRowPerPage);
  const [page, setPage] = useState<number>(defaultPage);
  const [dropMissed, setDropMissed] = useState<any>({ data: [], length: 0 });

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  useEffect(() => {
    WidgetsStore.setDropResults(
      location,
      'FAIL',
      10,
      1,
      '',
      '',
      setDropMissed
    );
  }, []);

  useEffect(() => {
    if (WidgetsStore.isLoadingDrop) return;
    WidgetsStore.setDropResults(
      location,
      'FAIL',
      rowPerPage,
      page,
      sortState,
      sortBy,
      setDropMissed
    );
  }, [page, rowPerPage, sortState, sortBy]);

  const toggleModal = () => setIsModalTrue(!isModalTrue);
  const setSortModel = (field: string, sortOrder: string | null) => {
    setSortBy(sortOrder || null);
    setSortState(field);
  };

  const setRowPerPageHandler = (val: number) => {
    setPage(1);
    setRowPerPage(val);
  };

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "feed" })}
      nameArray={NAMES}
      image={image}
      maxHeight="400px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  const components = {
    fullName: ({ row }: any) => (
      <FlexBox items="center" content="center">
        <span style={{  fontSize: "24px", fontWeight: 700, marginRight: "20px" }}>
          -
        </span>
        <Avatar
          variant="circular"
          src={row.user.imageUrl}
          sx={{ marginRight: 2 }}
        />
        <NameItem name={`${row.user.firstName} ${row.user.lastName}`} />
      </FlexBox>
    ),
    status: ({ row }: any) => {
      let status = 'Success';
      let color = "#000000";
      if (row.status !== DropTransactionStatusEnum.SUCCESS) {
        status = 'Missed';
        color = "#cf0f2e";
      }
      return (
        <Box
          sx={{
            width: 85,
            height: 25,
            border: "2px solid",
            borderColor: color,
            padding: "0 15px",
            borderRadius: 8,
          }}
        >
          <FlexBox items="center" content="center">
            <span style={{ color, fontWeight: "normal" }}>
              {status}
            </span>
          </FlexBox>
        </Box>
      );
    },
    data: ({ row, col }: any) => (
      <span>{moment(row[col]).format("MMMM Do YYYY, HH:mm")}</span>
    ),
    empty: () => null,
  };

  const Table = (
    <UiTableDrag
      loading={WidgetsStore.isLoadingDrop}
      sortModelInit={
        sortState && sortBy ? [{ field: sortState, sort: sortBy }] : []
      }
      sortSubmit={setSortModel}
      components={components}
      componentsTable={components}
      configDrag={dropResultsTableConfig()}
      data={toJS(dropMissed.data)}
      disablePagination={!dropMissed.length}
      setRowPerPage={setRowPerPageHandler}
      rowPerPage={rowPerPage}
      page={page}
      setPage={setPage}
      length={dropMissed.length}
      rowReordering={false}
      minHeight={150}
    />
  );

  return (
    <div style={{ marginTop: '50px' }}>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "missed" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <div className={classes.resultsTable}>{Table}</div>
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
