import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useHistory, useLocation } from "react-router-dom";
import { MainLayout } from "../../components";
import { AdditionalSettings } from "./components/addition-settings";
import { AdditionalSettingsDrop } from "./components/addition-settings-drop";
import { FlexBox, GridBox } from "../../components/wilson-ui/box";
import { Settings } from "./components/settings";
import { BaggedDrop } from "./components/bagged-drop";
import { MissedDrop } from "./components/missed-drop";
import { AddWidgetsSchema } from "./add-widgets-schema";
import { AddWidgetsDropSchema } from "./add-widgets-drop-schema";
import { useCreateWidgetsStyles } from "./create-widgets-style.hook";
import { schemas } from "./create-widgets-schema-options";
import ROUTING_CONFIG from "../../constants/routing-config/routing-config";
import { initialValues } from "./initeal-values";
import { AddWidgetsStorySchema } from "./add-widgets-story-schema";
import { sendFormHelper } from "./helpers/send-widget";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { WidgetTypeEnum } from "../../enum";
import { UpgradeWidgetToEditHelper } from "../../helpers/widgets/upgrade-widget-to-edit.helper";
import { Spiner } from "../../components/wilson-ui/spiner";
import { getWidget } from "../../actions/widgets";
import { usePageStyles } from "../pages-styles.hook";
import { DropTabLabel, DropTabValue } from "../../types";
import { useWidgetsPageStyles } from "../widgets/widgets-page-styles.hook";

export const DetailsDropWidgetsPage: React.FC = () => {
  const intl = useIntl();
  const [isWidgetFound, setIsWidgetFound] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstRenderEnd, setIsFirstRenderEnd] = useState(false);
  const [widgetsSchema, setWidgetsSchema] =
    useState<Yup.ObjectSchema<any>>(AddWidgetsSchema);
  const [additionSchema, setAdditionSchema] = useState<Yup.ObjectSchema<any>>(
    AddWidgetsStorySchema
  );
  const [tab, setTab] = useState(DropTabValue.details);
  const classesTitle = usePageStyles();
  const classes = useCreateWidgetsStyles();
  const widgetStyles = useWidgetsPageStyles();
  const history = useHistory();
  const location: Record<string, any> = useLocation();
  const formValues: any = initialValues;
  const form = useFormik({
    initialValues: formValues,
    validationSchema: additionSchema
      ? widgetsSchema.concat(additionSchema)
      : widgetsSchema,
    onSubmit: () => {
      sendFormHandler();
    },
  });

  useEffect(() => {
    getWidgetHandler();
  }, []);

  useEffect(() => {
    schemas.map((item) => {
      item.name.toUpperCase() === form.values.type.toUpperCase() &&
        setAdditionSchema(item.schema);
    });
    if (form.values.type.toUpperCase() === WidgetTypeEnum.DROP) {
      setWidgetsSchema(AddWidgetsDropSchema);
    }
    if (isFirstRenderEnd) {
      form.setValues(form.values);
    } else {
      setIsFirstRenderEnd(true);
    }
  }, [form.values.type]);

  const notify = () => toast(intl.formatMessage({ id: "something_is_wrong" }));

  const sendFormHandler = async () => {
    const isOk = await sendFormHelper(form.values);

    if (isOk) {
      form.setValues({ ...initialValues });
      history.push(ROUTING_CONFIG.widgets);
    } else {
      notify();
    }
  };

  const getWidgetHandler = async () => {
    const widgetIdPath =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (widgetIdPath) {
      const data = await getWidget(widgetIdPath);

      if (data.id) {
        const widgetToSet = UpgradeWidgetToEditHelper(data);
        form.setValues(widgetToSet);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsWidgetFound(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newValue: DropTabValue
  ) => {
    setTab(newValue);
  };

  const body = isWidgetFound ? (
    isLoading ? (
      <Spiner />
    ) : (
      <>
        <GridBox columns="auto 312px" className={classes.container} cg="50px">
          <div>
            <h2 className={classesTitle.pageTitle}>
              {intl.formatMessage({ id: "feed_widget_details" })}
            </h2>
            <TabContext value={tab}>
              <Box sx={{ marginTop: '50px' }}>
                <TabList
                  onChange={handleChangeTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  classes={{ root: widgetStyles.tabsRoot }}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={DropTabLabel.details}
                    value={DropTabValue.details}
                    classes={{ root: widgetStyles.tabRoot }}
                  />
                  <Tab
                    label={DropTabLabel.results}
                    value={DropTabValue.results}
                    classes={{ root: widgetStyles.tabRoot }}
                  />
                </TabList>
                <TabPanel
                  value={DropTabValue.details}
                  classes={{ root: widgetStyles.tabPanel }}
                >
                  <Settings {...{ form }} />
                </TabPanel>
                <TabPanel
                  value={DropTabValue.results}
                  classes={{ root: widgetStyles.tabPanel }}
                >
                  <BaggedDrop location={location} />
                  <MissedDrop location={location} />
                </TabPanel>
              </Box>
            </TabContext>
          </div>
          {form.values.type.toUpperCase() === WidgetTypeEnum.DROP ? (
            <AdditionalSettingsDrop {...{ form }} />
            ):!(
            form.values.type.toUpperCase() === WidgetTypeEnum.FEATURED_CAROUSEL
          ) ? <AdditionalSettings {...{ form }} />: null}
        </GridBox>
      </>
    )
  ) : (
    <FlexBox items="center" content="center">
      <h3 className={classes.errorTitle}>
        {intl.formatMessage({ id: "widget_not_found" })}
      </h3>
    </FlexBox>
  );

  return <MainLayout>{body}</MainLayout>;
};
