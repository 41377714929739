import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    submission_date: "Submission Date",
    export: "Export",
    users: `Users`,
    interests: 'Interests',
    interests_details: 'Interests Details',
    create_interest: "Create an Interest",
    category: 'Category',
    interest_not_found: 'Interest Not Found',
    campaigns: 'Campaigns',
    campaign: 'Campaign',
    select_campaign: 'Select Campaign',
    campaigns_details: 'Campaigns Details',
    create_campaign: "Create a Campaign",
    campaign_not_found: 'Campaign Not Found',
    prize: 'Prize',
    prizes: 'Prizes',
    card_color: 'Card Color',
    update_card_color: "Card Color was updated successfully!",
    update_title: "Title was updated successfully!",
    add_prizes: 'Add Prizes',
    prize_name: 'Prize Name',
    prize_url: 'Prize URL',
    typeform_embed_code_url: 'Typeform Embed Code',
    typeform_url: 'Typeform URL',
    exit: "Exit",
    logout: "Logout",
    language: "Language",
    time_zone: "Time zone",
    export_current_view: "Export current view",
    id: "ID",
    name: "Name",
    add_name: "Add Name",
    campaign_name: "Campaign Name",
    categories: 'Categories',
    hits_misses: 'Hits/Misses',
    hits: 'Hits',
    misses: 'Misses',
    email: "Email",
    location: "Location",
    phone: "Phone",
    login: "Login",
    last_login: "Last login",
    created_at: "Created at",
    created_on: "Created On",
    scheduled_for: "Scheduled For",
    qty: 'QTY',
    claimed: 'Claimed',
    paused: 'Paused',
    whitelisted: "Whitelisted",
    explicitsms: "Test Notify",
    rows_per_page: "Rows per page",
    feed_widgets: "Feed widgets",
    create_a_widget: "Create a Widget",
    header: "Header",
    radius: "Radius (ft)",
    add_radius: "Add Radius",
    location_alias: "Location Alias",
    add_location_alias: "Add Location Alias",
    title: "Title",
    subtitle: "Subtitle",
    add_subtitle: "Add Subtitle",
    type: "Type",
    trigger: 'Trigger',
    start_expiration_date: "Start and Expiration Date",
    start_expiration_time: "Start and Expiration Time",
    start_date: "Start Date",
    start_time: "Start Time",
    live_date: "Go Live Date",
    live_time: 'Go Live Time',
    expiration_date: "Expiration Date",
    expiration_time: "Expiration Time",
    channels: "Channels",
    entries: 'Entries',
    crawler_settings: "Crawler Settings",
    drop_exclusive: "Drop Exclusive",
    actions: "Actions",
    override_image: "Override Image",
    exclusive: "Exclusive",
    full_name: "Full Name",
    trigger_buy: "Triggered Buy",
    completed_buy: "Completed Buy",
    outcome: "Outcome",
    bagged: "Bagged",
    create_a_new_widget: "Create a new widget",
    feed_widget_details: "Feed widget details",
    location_info: "Location Info",
    address: 'Address',
    general_setting: "General settings",
    background_color_hex_value: "Background color (HEX)",
    website_url: "Website URL",
    enter_terms_and_conditions: "Enter Terms and Conditions",
    redemption_instructions: "Redemption Instructions",
    scan_required_to_unlock: "Scan Required to Unlock",
    favorite_icon: "Favorite Icon",
    countdown_time: "Countdown Timer",
    share_icon: "Share Icon",
    audio: "Audio",
    title_visibility: "Title Visibility",
    button_visibility: "Button Visibility",
    tags: "Tags",
    add_feed_items: "Add Feed Items",
    feed_items: "Feed Items",
    feed_assets: "Feed assets",
    border_color: "Border Color (HEX)",
    timer_color: "Countdown Timer Color (HEX)",
    title_highlight_color: "Title Highlight Color (HEX)",
    title_color: "Title Color (HEX)",
    title_size: "Title Size",
    button_primary: "Button Primary",
    post_redemption_button_color: "Post-Redemption Button (HEX)",
    button_text: "Button Text",
    button_color: "Button Fill Color (HEX)",
    button_text_color: "Button Text Color (HEX)",
    button_fill_color: "Button Fill Color (HEX)",
    primary_background_color: "Primary Background (HEX)",
    win_background_color: "Win Background (HEX)",
    miss_background_color: "Miss Background (HEX)",
    success_background_color: "Success Background (HEX)",
    drop_csv: "Drag and Drop CSV",
    drop_image: "Drag and Drop image",
    drop_video: "Drag and Drop video",
    drop_image_video: "Drag and Drop image or video",
    browse_file: "Browse files",
    or: "or",
    details_assets: "Details Assets",
    success_assets: "Success Assets",
    locker_assets: "Locker Assets",
    interest_assets: "Interest Assets",
    upload_offer_codes: "Upload Offer Codes (.CSV)",
    physical_good: "Physical Good",
    digital_good: "Digital Good",
    pinned_assets: "Pinned Assets",
    fullscreen_alert_assets: "Fullscreen Alert Assets",
    missed_assets: "Missed Assets",
    favorite_thumbnail: "Favorite Thumbnail",
    story_assets: "STORY ASSETS",
    button_style: "Button Style",
    status: "Status",
    feed_submissions: "Feed Submissions",
    add_submission: "Add Submission",
    email_address: "Email Address",
    feed_widget_title: "Feed Widget title",
    feed_widget_type: "Feed Widget type",
    winner: "Winner",
    create_a_feature_carousel: "Create a Featured Carousel",
    edit_a_feature_carousel: "Edit a Featured Carousel",
    no_child_widgets: "This featured carousel seems to have no child widgets!",
    feed_widget: "Feed Widget",
    widgets: "Widgets",
    submissions: "Submissions",
    table_is_empty: "Table is Empty",
    carousel_is_empty: "Featured Carousel is Empty",
    download_table: "Download Table",
    export_data: "Export Data",
    date_range: "Date Range",
    export_one_file: "Export 1 File",
    export_all: "Export all",
    exporting: 'Exporting file...',
    export_date_range: 'Export with date range',
    this_month: 'This month',
    last_month: 'Last month',
    last_3_months: 'Last 3 months',
    last_6_months: 'Last 6 months',
    reset: 'Reset',
    author_name: "Author Name",
    author_name_color: "Author Name Color (HEX)",
    description: "Description",
    upload_avatar: "Upload Avatar",
    assign: "Assign",
    post: "Post",
    story: "Story",
    external_link: "External Link",
    carousel: "Featured Carousel",
    drop: "Drop",
    promotion: "Promotion",
    instant_win: "Instant Win",
    widget: 'Widget',
    widget_type: "Widget Type",
    winner_type: "Winner Type",
    search_by_title: "Search by Title",
    something_is_wrong: "something is wrong",
    upload_image_success: "Upload image is success",
    error_interest_exists: "Interest with such name already exists",
    error_campaign_exists: "Campaign with such name already exists",
    settings: "Settings",
    add: "Add",
    edit: "Edit",
    duplicate: "Duplicate",
    duplicate_widget_success: "Duplicate widget is success",
    add_title: "Add Title",
    enter_hex_value: "Enter HEX value",
    terms_and_conditions: "Terms and Conditions",
    select_a_type: "Select a Type",
    feed: "Feed",
    scheduled: "Scheduled",
    story_preview: "Story Preview",
    post_preview: "Post Preview",
    url: 'URL',
    enter_website_url: "Enter web-site url",
    image_map_json: "Image Map JSON",
    image_map: "Image Map",
    add_button_text: "Add button text",
    add_author_name: "Add author name",
    add_description: "Add description",
    set_status: "Set status",
    promotion_background_color: "Promotion background color (HEX)",
    promotion_title: "Promotions title",
    promotion_title_color: "Promotions title Color (HEX)",
    promotion_description: "Promotions description",
    promotion_description_color: "Promotions description Color (HEX)",
    add_promotion_title: "Add promotion title",
    add_promotion_description: "Add promotion description",
    promotion_modal_visibility: "Promotions modal",
    promotion_modal_background_image: "Promotion modal background image",
    promotion_modal_title: "Promotion modal title",
    add_promotion_modal_title: "Add promotion modal title",
    yes: "Yes",
    no: "No",
    are_you_sure: "are you sure?",
    please_set_one_item: "please set one item",
    swipe_up_url: "Swipe Up URL",
    story_slides: "Story Slides",
    feed_media: "Feed Media",
    main_promotion_image: "Main promotion image",
    collaboration_image: "Collaboration image",
    modal_box_image: "Modal box image",
    select_image_file: "Please select an image file",
    only_csv_file: "You can choose only CSV files for this field",
    only_image_file: "You can choose only image files for this field",
    only_video_file: "You can choose only video files for this field",
    only_image_and_video_file:
      "You can choose only image and video files for this field",
    only_allowed_video_extension: "Video can only be in MP4 or MOV format",
    only_allowed_video_extension_mp4: "Video can only be in MP4 format",
    up_to_100_mb: "File size must be up to 100 Mb",
    file_not_empty: "File is not empty.",
    file_empty: "File is empty.",
    file_csv_not_contain: "Uploaded CSV doesn’t contain any promo codes. Please upload valid CSV.",
    promotion_modal_background_color: "Promotion modal background color (HEX)",
    promotion_modal_title_color: "Promotion modal title color (HEX)",
    min_length_1: "Min length 1",
    min_length_3: "Min length 3",
    min_0: 'Min number of 0',
    min_1: 'Value must be a number greater than 0',
    min_length_5: "Min length 5",
    max_length_50: "Max length 50",
    max_length_65: "Max length 65",
    max_length_50000: "Max length 50000",
    required: "Required*",
    Required: "Required*",
    invalid_address: "Invalid Address",
    please_fill_in_all_required_fields: "Please fill in all required fields",
    please_fill_in_all_fields_in_the_correct_format:
      "Please fill in all fields in the correct format",
    please_fill_in_all_date_fields_in_the_correct_format_date:
      "Please fill in all date fields in the correct format MM/DD/YYYY",
    please_enable_favorite_toggle_button:
      "Please enable the favorite toggle button",
    must_correspond: "Must correspond to the format",
    save: "Save",
    delete: "Delete",
    widget_not_found: "Widget not found",
    carousels: "Carousels",
    add_carousel: "Add Featured Carousel",
    edit_carousel: "Edit Featured Carousel",
    carousel_title: "Carousel Title",
    carousel_title_color: "Carousel Title Color (HEX)",
    carousel_background_color: "Carousel Background Color (HEX)",
    cancel: "Cancel",
    upload: "Upload",
    view: "View",
    enter_drop_url: "Enter drop url",
    drop_url: "Drop URL",
    drop_name: "Drop Name",
    enter_drop_name: "Enter Drop Name",
    rich_content: "Rich content",
    rich_text: "Rich Text",
    countdown_video: "Countdown video",
    trigger_fullscreen_gate: "Trigger Fullscreen Gate",
    number_of_hours_before_drop: "Number of hours before drop",
    number_of_hours: "Number of hours",
    number_of_seconds_before_drop: "Number of seconds before drop",
    number_of_seconds: "Number of seconds",
    full_height: "Full Height",
    pinned: "Pinned",
    missed: "Missed",
    countdown_text_color: "Countdown Text Color (HEX)",
    countdown_fill_color: "Countdown Fill Color (HEX)",
    background_color: "Background Color (HEX)",
    description_color: "Description Color (HEX)",
    primary_title: "Primary Title",
    primary_image: "Primary Image",
    fullscreen_alert: "Fullscreen Alert",
    background: "Background",
    container_color: "Container Color",
  },
  [LOCALES.RUSSIAN]: {
    save: "Сохранить",
    delete: "Удалить",
    widget_not_found: "Виджет не найден",
    must_correspond: "должно соответствовать регулярному выражению",
    please_fill_in_all_required_fields:
      "Будь ласка, заповніть всі обов'язкові поля",
    please_fill_in_all_fields_in_the_correct_format:
      "Пожалуйста, заполните все поля в правильном формате",
    please_enable_favorite_toggle_button:
      "Пожалуйста, включите кнопку переключения избранного",
    min_length_1: "минимальная длина 1 символ",
    min_length_3: "минимальная длина 3 символа",
    min_length_5: "минимальная длина 5 символов",
    max_length_50: "максимальная длина 50 символов",
    max_length_50000: "максимальная длина 50000 символов",
    required: "обезательно*",
    add_promotion_modal_title: "Добавить заголовок модального окна продвижения",
    promotion_modal_title: "Заголовок модального окна продвижения",
    promotion_modal_background_color:
      "Задний фон модального окна продвижения (HEX)",
    add_promotion_title: "Добавить заголовок продвижения",
    add_promotion_description: "Добавить писания продвижения",
    promotion_title: "Заголовок продвижения",
    promotion_description: "Описания продвижения",
    promotion_background_color: "Цвет фона продвижения (HEX)",
    users: "Пользователи",
    export: "Экспорт",
    exit: "Закрыть",
    logout: "Выйти",
    language: "Язык",
    time_zone: "Часовой пояс",
    export_current_view: "Экспорт текущего представления",
    id: "ИД",
    login: "Войти",
    name: "Имя",
    email: "Е-меил",
    location: "Локация",
    phone: "Телефон",
    last_login: "Последний логин",
    created_at: "Создано",
    whitelisted: "Белый список",
    explicitsms: "Тестировать оповещение",
    rows_per_page: "Строк на странице",
    feed_widgets: "Виджеты каналов",
    create_a_widget: "Создать Виджет",
    title: "Заголовок",
    type: "Тип",
    start_date: "Дата старта",
    expiration_date: "Дата окончания",
    channels: "Каналы",
    exclusive: "Ексклюзивность",
    create_a_new_widget: "Создать новый виджет",
    general_setting: "Глобальные настройки",
    background_color_hex_value: "Цвет фона HEX-значение",
    website_url: "Адресс сайта",
    enter_terms_and_conditions: "Введите условия и положения",
    terms_and_conditions: "Условия и Положения",
    scan_required_to_unlock: "Сканирование для разблокировки",
    favorite_icon: "Избранный значок",
    countdown_time: "Обратный отсчет",
    share_icon: "Значок поделиться",
    title_visibility: "Видимость заголовка",
    button_visibility: "Видимость кнопки",
    tags: "Теги",
    feed_assets: "Ленты актив",
    title_color: "Цвет Заголовка (HEX)",
    title_size: "Размер заголовка",
    button_text: "Текст Кнопки",
    button_color: "Цвет Кнопки",
    button_text_color: "Цвет Текста Кнопки",
    drop_image: "Перетащите изображение",
    browse_file: "Выбрать файл",
    or: "или",
    details_assets: "Детали активов",
    success_assets: "Успешный активов",
    favorite_thumbnail: "Любимый значок",
    story_assets: "История активов",
    button_style: "Стиль кнопки",
    status: "Статус",
    feed_submissions: "Отправка Предсталения",
    add_submission: "Добавить Представления",
    email_address: "Емейл Адрес",
    feed_widget_title: "Заголовок виджета",
    feed_widget_type: "Тип виджета",
    winner: "Победитель",
    create_a_feature_carousel: "Создать карусель",
    edit_a_feature_carousel: "Редактировать карусель",
    no_child_widgets: "Похоже, что у этой карусели нет дочерних виджетов!",
    feed_widget: "Виджет канала",
    widgets: "Виджеты",
    submissions: "Представления",
    table_is_empty: "Таблица пуста",
    download_table: "Скачать таблицу",
    export_all: "Експортировать все",
    author_name: "Имя автора",
    author_name_color: "Цвет имени автора (HEX)",
    description: "Описание",
    upload_avatar: "Загрузить Аватар",
    assign: "Назначить",
    post: "Пост",
    story: "История",
    external_link: "Внешняя ссылка",
    widget_type: "Тип Виджета",
    winner_type: "Тип Победителя",
    search_by_title: "Поиск по Заголовку",
    something_is_wrong: "что-то пошло не так",
    add: "Добавить",
    settings: "Настройки",
    edit: "Редактировать",
    add_title: "Добавить заголовок",
    subtitle: "Подзаголвок",
    add_subtitle: "Добавить подзаголовок",
    enter_hex_value: "Введите HEX значение",
    select_a_type: "Выбирите тип",
    feed: "Новость",
    story_preview: "Просмотр истории",
    post_preview: "Просмотр поста",
    enter_website_url: "Введите урл сайта",
    add_button_text: "Введите текст кнопки",
    add_author_name: "Введите имя автора",
    add_description: "Введите описания",
    set_status: "Укажите статус",
    yes: "Да",
    no: "Нет",
    are_you_sure: "Вы уверены?",
    please_set_one_item: "Пожалуста укажите один елемент",
    swipe_up_url: "Ссылка для перехода",
    main_promotion_image: "Главное рекламное изображение",
    collaboration_image: "Изображение для совместной работы",
    modal_box_image: "Изображение модального окна",
    only_image_file:
      "Вы можете выбрать только файлы изображений для данного поля",
    only_image_and_video_file:
      "Вы можете выбрать только файлы изображений и видео для данного поля",
    only_allowed_video_extension:
      "Видео может быть только в формате MP4 или MOV",
    up_to_100_mb: "Размер файла должен быть до 100 Мб",
    submission_date: "Дата подання",
    carousels: "Карусели",
    add_carousel: "Добавить карусель",
    edit_carousel: "Редактировать карусель",
    cancel: "Отменить",
    view: "Посмотреть",
  },
};
