import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  MutableRefObject,
} from "react";
import { useIntl } from "react-intl";
import { findIndex, isEmpty } from "lodash";
import {
  DataGridPro,
  GridRowModel,
  GridSortModel,
  GridRowParams,
  GridRowOrderChangeParams,
  GridRenderCellParams,
  GridRowHeightParams,
  GridEventListener,
  useGridApiEventHandler,
  useGridApiRef,
  GridApiCommon,
  GridEventLookup,
} from "@mui/x-data-grid-pro";
import { TableRow as MuiTableRow, TableCell } from "@material-ui/core";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { IRowColumn, ITableDragConfig } from "./components/interface";
import { useTableStyles } from "./table-styles.hook";
// import { useTableHeaderStyles } from "./components/table-header/table-header-styles.hook";
import { useTableBodyStyles } from "./components/table-body/table-body-styles.hook";
import { HeaderColumn } from "./components/header-column";
import { TableBody } from "./components/table-body";
import { SearchInput } from "../../../components/wilson-ui/inputs";
import { FlexBox } from "../box";
import { EMPTY_FUNCTION } from "../../../constants/addional/empty-function";
import { WidgetTypeEnum } from "../../../enum";
interface IProps {
  configDrag: ITableDragConfig;
  data: Array<Record<string, any>>;
  componentsTable?: Record<string, any>;
  components?: Record<string, any>;
  setRowPerPage?: (count: number) => void;
  setPage?: (count: number) => void;
  rowPerPage?: number;
  page?: number;
  length: number;
  rowReordering: boolean;
  disablePagination?: boolean | undefined;
  sortModelInit: GridSortModel;
  isSortType?: boolean;
  sortSubmit?: (field: string, sortOrder: string | null) => void;
  sortTypeSubmit?: () => void;
  setRowPriority?: (payload: Array<Record<string, any>>) => void;
  setSearch?: (term: string) => void;
  searchValue?: string;
  searchTable?: boolean;
  loading?: boolean;
  onLoading?: (loading: boolean) => void;
  minHeight?: number;
}

function updateRowPosition(
  initialIndex: number,
  newIndex: number,
  rows: Array<GridRowModel>,
  setRowPriority: (payload: Array<Record<string, any>>) => void
): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
      if (newIndex !== initialIndex) {
        const payload = [{ id: row.id, carouselId: row.carouselId, widgetPriority: newIndex + 1 }];
        if (newIndex > initialIndex) {
          for (let i = initialIndex; i < newIndex; i++) {
            payload.push({
              id: rows[i + 1].id,
              carouselId: rows[i + 1].carouselId,
              widgetPriority: i + 1,
            });
          }
        } else {
          for (let i = newIndex; i < initialIndex; i++) {
            payload.push({
              id: rows[i].id,
              carouselId: rows[i].carouselId,
              widgetPriority: i + 2,
            });
          }
        }
        setRowPriority(payload);
      }
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

export const UiTableDrag: React.FC<IProps> = ({
  configDrag,
  data,
  componentsTable,
  components,
  setRowPerPage,
  rowPerPage,
  setPage,
  page,
  length,
  disablePagination,
  rowReordering,
  sortModelInit,
  sortSubmit,
  sortTypeSubmit,
  isSortType,
  setRowPriority,
  setSearch,
  searchValue,
  searchTable,
  loading,
  onLoading,
  minHeight
}) => {
  const intl = useIntl();
  const classes = useTableStyles();
  // const classesHeader = useTableHeaderStyles();
  const classesBody = useTableBodyStyles();
  const componentsObject: IRowColumn = {};

  const [isResizing, setIsResizing] = useState<boolean>(false);

  const tablePage: number =
    !disablePagination && typeof page === "number" ? page - 1 : 1;

  const tableRowsPerPage: number =
    !disablePagination && typeof rowPerPage === "number" ? rowPerPage : 1;

  const sortSubmitHandler = sortSubmit
    ? sortSubmit
    : () => {
        EMPTY_FUNCTION();
      };
  const sortTypeSubmitHandler = sortTypeSubmit
    ? sortTypeSubmit
    : () => {
        EMPTY_FUNCTION();
      };

  const [rows, setRows] = useState(data);
  const [loading2, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>(sortModelInit);
  const [rowExpanded, setRowExpanded] = useState<any>(null);

  useEffect(() => {
    setRows(data);
  }, [data]);
  useEffect(() => {
    setSortModel(sortModelInit);
  }, [sortModelInit]);

  const handlePageChange = (page: number) => {
    setPage && setPage(page + 1);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setRowPerPage && setRowPerPage(pageSize);
  };

  const handleSortModelChange = (mode: GridSortModel) => {
    setSortModel(mode);
    const field = mode[0] ? mode[0].field : "";
    const sort: any = mode[0] ? mode[0].sort : null;
    sortSubmit && !isResizing && sortSubmit(field, sort);
  };

  const handleSearch = useCallback(
    (term: string) => {
      setSearch && setSearch(term);
    },
    [setSearch]
  );

  const handleRowHeight = (params: GridRowHeightParams) => {
    if (params.model?.isSelected && params.model?.length) {
      const xheight =
        params.model.length >= 4
          ? params.model.length / 2
          : params.model.length;
      return 72 * xheight;
    }
    return 72;
  };

  const getDetailPanelHeight = (params: GridRowParams) => {
    const xheight = params.row.childWidgets.length || 1;
    return xheight * 72 + 20;
  };

  const getDetailPanelContent = (params: GridRowParams) => {
    if (params.row.type === WidgetTypeEnum.CAROUSEL) {
      const compTable: Record<string, any> = componentsTable
        ? componentsTable
        : componentsObject;
      const defaultTableBodyProps = {
        components: compTable,
        columns: configDrag.columns,
        data: params.row?.childWidgets || [],
        rowCarousel: params.row,
        rowColumns: configDrag.rowColumnsCarouselDrag || "",
        rowColumnsCarousel: configDrag.rowColumnsCarouselDrag || "",
      };
      return (
        <MuiTableRow
          className={classesBody.container}
          style={{ gridTemplateColumns: configDrag.rowColumnsCarouselDrag }}
        >
          {!isEmpty(defaultTableBodyProps.data) ? (
            <TableBody {...defaultTableBodyProps} />
          ) : (
            <FlexBox
              content="center"
              items="center"
              className={classes.emptySpanCarousel}
            >
              {intl.formatMessage({ id: "carousel_is_empty" })}
            </FlexBox>
          )}
        </MuiTableRow>
      );
    }
    return null;
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    setLoading(true);
    const newRows = setRowPriority ? await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
      setRowPriority
    ): null;

    setRows(newRows);
    setLoading(false);
  };

  const handleSelectRow = useCallback(
    (row: any, length: number) => {
      const index = findIndex(rows, (item: any) => item.id === row.id);
      const selected = index > -1 ? rows[index] : null;
      if (selected?.isSelected) {
        rows[index].isSelected = false;
      } else {
        rows[index].isSelected = true;
      }
      rows[index].length = length;
      setRows([...rows]);
    },
    [rows]
  );

  const handleSelectRowExpanded = useCallback(
    (row: any) => {
      if (row?.id === rowExpanded?.id) {
        setRowExpanded(null);
      } else setRowExpanded(row);
    },
    [rowExpanded]
  );

  const renderCarousel = (
    components: Record<string, any>,
    componentName: string,
    name: string,
    row: any
  ) => {
    switch (name) {
      case "title":
        return (
          <FlexBox items="center" content="flex-start">
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ marginRight: 2 }}
              onClick={() => handleSelectRowExpanded(row)}
            >
              {!(row?.id === rowExpanded?.id) ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
            {row[name]}
          </FlexBox>
        );
      case "type":
        return components[componentName]({ row });
      case "edit":
        return (
          <TableCell style={{ padding: "16px 0", borderBottom: "none" }}>
            {components[componentName]({ row, col: name })}
          </TableCell>
        );
      default:
        return "";
    }
  };

  const columns: any = useMemo(
    () =>
      configDrag.columns.map((column: Record<string, any>) => ({
        ...column,
        renderHeader: () => (
          <HeaderColumn
            title={column.headerName}
            type={column.filterType}
            name={column.field}
            isSortType={isSortType}
            key={Math.random().toString().substr(2)}
            sortTypeSubmit={sortTypeSubmitHandler}
          />
        ),
        renderCell: (params: GridRenderCellParams) => {
          const comp: Record<string, any> = components
            ? components
            : componentsObject;
          if (params.row.type === WidgetTypeEnum.CAROUSEL) {
            const component = renderCarousel(
              comp,
              column.componentName,
              column.field,
              params.row
            );
            return component && component;
          }
          if (column.type === "component")
            return comp[column.componentName]({
              row: params.row,
              col: column.field === "edit" ? params.row : column.field,
              handleSelectRow,
            });
          return params.row[column.field];
        },
      })),
    [
      components,
      sortSubmitHandler,
      sortTypeSubmitHandler,
      handleSelectRow,
      handleSelectRowExpanded,
    ]
  );

  const apiRef = useGridApiRef();

  const onResizeStartEvent: GridEventListener<"columnResizeStart"> = () => {
    setIsResizing(true);
  };

  const onResizeStopEvent: GridEventListener<"columnResizeStop"> = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (apiRef.current.subscribeEvent) {
      apiRef.current.subscribeEvent("columnResizeStart", onResizeStartEvent);
      apiRef.current.subscribeEvent("columnResizeStop", onResizeStopEvent);
    }
  }, [apiRef]);

  useGridApiEventHandler(
    "columnResizeStart" as unknown as MutableRefObject<GridApiCommon>,
    onResizeStartEvent as unknown as keyof GridEventLookup
  );
  useGridApiEventHandler(
    "columnResizeStop" as unknown as MutableRefObject<GridApiCommon>,
    onResizeStopEvent as unknown as keyof GridEventLookup
  );

  return (
    <div
      className={`${classes.container} ${
        !rowReordering ? classes.containerNoDrag : ""
      }`}
      style={{ minHeight: minHeight || 520, width: "100%" }}
    >
      {searchTable && (
        <FlexBox content="space-between" items="center" marginBottom="30px">
          <SearchInput
            value={searchValue || ''}
            setLoading={onLoading}
            submit={handleSearch}
          />
          <div>{length} Results</div>
        </FlexBox>
      )}

      <DataGridPro
        loading={loading || loading2}
        apiRef={apiRef}
        rows={rows}
        sortingMode="server"
        paginationMode="server"
        columns={columns}
        rowReordering={rowReordering}
        autoHeight
        // disableColumnMenu
        disableColumnReorder
        // disableColumnResize
        disableColumnSelector
        disableMultipleSelection
        disableDensitySelector
        disableSelectionOnClick
        disableMultipleColumnsSorting
        disableVirtualization
        sortModel={sortModel}
        pagination={!disablePagination}
        rowCount={length}
        // rowThreshold={2}
        page={tablePage}
        pageSize={tableRowsPerPage}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 25, 50]}
        onRowOrderChange={handleRowOrderChange}
        onSortModelChange={handleSortModelChange}
        getRowHeight={handleRowHeight}
        detailPanelExpandedRowIds={rowExpanded ? [rowExpanded.id] : []}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        classes={{
          columnHeaders: classes.columnHeaders,
          virtualScroller: classes.virtualScroller,
          "row--lastVisible": classes.rowlastVisible,
          footerContainer: rowReordering
            ? classes.footerContainer
            : classes.footerContainerNoDrag,
          rowCount: rowReordering ? classes.rowCount : "",
        }}
        components={{
          ColumnSortedDescendingIcon: ArrowDownwardOutlinedIcon,
          ColumnSortedAscendingIcon: ArrowUpwardOutlinedIcon,
          NoRowsOverlay: () => (
            <FlexBox
              content="center"
              items="center"
              className={classes.emptySpan}
            >
              {intl.formatMessage({ id: "table_is_empty" })}
            </FlexBox>
          ),
        }}
      />
    </div>
  );
};

export default UiTableDrag;
