export const ROUTING_CONFIG = {
  any: "*",
  auth: "/auth",
  confirmAuth: "/confirm-auth",
  users: "/users",
  createUserInterest: "/create-interest/",
  userInterests: "/interests",
  userInterest: "/interests/:id",
  carousels: "/carousels",
  carousel: "/carousel/:id",
  submissions: "/submissions",
  widgets: "/widgets",
  createWidget: "/create-widget/",
  createwidgetCarousel: "/create-carousel/:id",
  dropWidget: "/widget/drop/:id",
  widget: "/widget/:id",
  createCampaign: "/create-campaign/",
  campaigns: "/campaigns",
  campaign: "/campaigns/:id",
};

export default ROUTING_CONFIG;
